.section-ebooks {
    width: 75%;
    margin: 0 auto;
}

.content-ebook img {
    width: 10em;
    height: 14em;
    border-radius: 0.3em;
}

.info-data-ebbok h2 {
    text-align: start;
}

.contente-info-ebook {
    margin-bottom: 5em;
    margin-top: 5em;
}

.content-ebook {
    color: #5B5353;
    background-color: #fff;
    padding: 2em;
    border-radius: 1em;
    margin-top: 1em;
    margin-bottom: 0.5em;
    transition: all 0.5s;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.135);

}

.content-ebook:hover {
    background-color: #f9f9f9;

}



.info-data-ebbok {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.content-ebook p {

    margin-top: 1em;
}

.button-down {
    display: flex;
    justify-content: end;
    margin-bottom: 0.5em;
}

.button-down h3 {
    background-color: cadetblue;
    padding: 0.2em 0.4em;
    color: #fff;
    border-radius: 0.5em;
    cursor: pointer;
    transition: all 0.5s;
}

.button-down h3:hover {
    background-color: rgb(48, 96, 97);

}

.custom-modal-content {

    display: flex;
    /* Centraliza verticalmente */
    flex-direction: column;
    /* Alinha o conteúdo verticalmente */
    align-items: center;
    /* Alinha o conteúdo horizontalmente ao centro */
    justify-content: center;
    /* Alinha o conteúdo verticalmente ao centro */
}

/* Estilos para desabilitar o scroll */
.modal-open {
    overflow: hidden;
}

.custom-modal-content h2 {
    color: #fff;
    margin-bottom: 2em;
    text-align: center;
}

.input-tam {
    width: 50%;
    padding: 1.5em 2em;
    border-radius: 1em;
    border: none;
    font-size: 12pt;
    color: #5B5353;
    font-weight: 800;
}

.downloadPDF {
    width: 50%;
    padding: 1.5em 2em;
    border-radius: 1em;
    border: none;
    font-size: 12pt;
    color: #E35568;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.5s;

}

.downloadPDF:hover {
    background-color: #d4d4d4;

}

.button-down-mob {
    display: none;
}

.text-dow {
    color: #5B5353;
    font-weight: 900;
    font-size: 24pt;
    margin-bottom: 1em;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.content {
    width: 50%;
    margin: auto;
    background: #E35568;
    border-radius: 1em;
    padding: 20px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    border: none;
    margin-top: 2em;

}

.selectinput{
    margin-top: 1em !important;

}
.input-tam div{
    background-color: #fff !important;
}


/* Adicione este CSS para ocultar o menu principal em dispositivos móveis */
@media (max-width: 768px) {
    .info-data-ebbok {
        display: grid;
        grid-template-columns: 1fr;
    }

    .content-ebook img {
        width: 100%;
        height: 14em;
        border-radius: 0.3em;
    }

    .button-down {
        display: none;
        justify-content: end;
        margin-bottom: 0.5em;
    }

    .button-down-mob {
        display: flex;
        justify-content: center;
        margin-bottom: 0.5em;
        margin-top: 2em;
    }

    .button-down-mob h3 {
        background-color: cadetblue;
        padding: 0.5em 1em;
        color: #fff;
        border-radius: 0.5em;
        cursor: pointer;
        transition: all 0.5s;
        width: 100%;
        text-align: center;
    }

    .info-data-ebbok p {
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        color: #777;
        display: -webkit-box;
        margin-bottom: 0.3em;
        max-height: 6em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .section-ebooks {
        width: 90%;
        margin: 0 auto;
    }

    .contente-info-ebook {
        margin-bottom: 5em;
        margin-top: 3em;
    }

    .text-dow {
        color: #5B5353;
        font-weight: 900;
        font-size: 20pt;
        margin-bottom: 1em;
        TEXT-ALIGN: center;
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        transition: opacity 0.3s ease;
        position: initial;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.533);
    }

    .content {
        width: 80%;
        margin: auto;
        background: #E35568;
        border-radius: 1em;
        padding: 20px;
        transition: opacity 0.3s ease, transform 0.3s ease;
        border: none !important;
        margin-top: 7em;

    }


    .input-tam {
        width: 80%;
        padding: 1.5em 2em;
        border-radius: 1em;
        border: none;
        font-size: 12pt;
        color: #5B5353;
        font-weight: 800;
    }

    .downloadPDF {
        width: 100%;
        padding: 1.5em 2em;
        border-radius: 1em;
        border: none;
        font-size: 12pt;
        color: #E35568;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.5s;
    }
}