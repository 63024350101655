/* .section-redes-sociais {
    display: none;
} */

.header-menu {
    background-color: #fff;

}

.header-menu .content-menu-top {
    width: 75%;
    margin: 0 auto;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1em;
    align-items: center;
}

.header-menu .content-menu-top img {
    width: 7.5em
}




.header-menu .option-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 1em;
    column-gap: 1em;
}

.header-menu .content-menu-top a {
    text-decoration: none;
}

.header-menu .content-menu-top p {
    font-weight: bold;
    color: #767070;
    font-size: 13pt;
    transition: color 0.5s;

}

.header-menu .content-menu-top p:hover {

    color: #E35568;


}

.header-menu.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-img {
    display: flex;
    justify-content: center;
}

span {
    cursor: pointer;
}


/* Adicione este CSS para o menu móvel */
.mobile-menu {
    position: fixed;
    top: 80px;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
    z-index: 999;
}

.mobile-menu.open {
    left: 0;
}

.mobile-menu-toggle {
    display: flex !important;
    cursor: pointer;
    padding: 10px;
    z-index: 1000;
    justify-content: end;
}

.mobile-menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 3px 0;
    transition: transform 0.3s ease-in-out;
}


.mobile-menu-toggle {
    color: #E35568;
}


.mobile-menu-toggle.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.mobile-menu-toggle.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}


.content-info-services-mobile {
    display: none;
}

.mobile-menu-toggle {
    display: none !important;
}

.content-info-service-value-mob {
    display: none;
}

.info-org-recursos-mob {
    display: none;
}

.icones a .icone-tam {
    font-size: 2.5em;
}
.mobile-button-rs{
    display: none;
}

/* Adicione este CSS para ocultar o menu principal em dispositivos móveis */
@media (max-width: 768px) {
    .option-menu {
        display: none !important;
    }

    .mobile-menu-toggle {
        display: block !important;
    }

    .content-info-services-mobile {
        display: block;
    }

    .mobile-menu-toggle {
        display: flex;
    }

    .content-menu-top {
        display: flex;
        justify-content: space-between;
    }

    .header-menu .content-menu-top img {
        width: 4.5em;
    }

    .mobile-menu {
        background-color: #E35568;
        color: #fff;

    }

    .position-optins-menu {
        display: grid;
        justify-content: center;
        margin-top: 2em;
    }


    .header-menu .content-menu-top p {
        font-weight: bold;
        color: #ffffff;
        font-size: 20pt;

        text-align: center;
        margin-top: 1em;
    }

    .header-menu .content-menu-top p:hover {

        color: #ffffff;
    }


    .content-info-home .buttom-flc {
        margin-top: 2em;
        padding: 1em 6em;
        font-size: 10pt;
        font-weight: bold;
        border: none;
        border-radius: 0.5em;
        color: #fff;
        background-color: #E35568;
        cursor: pointer;
        transition: all 0.5s;
        text-transform: uppercase;
    }

    .section-info-slider {
        position: relative;
        height: 44vh;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .content-info-home h1 {
        color: #fff;
        font-weight: 900;
        font-size: 24pt;
        font-family: 'Inter', sans-serif;
        line-height: 1em;
    }

    .section-info-consulting .info-01 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .section-info-consulting .info-01 p {
        color: #5B5353;
        font-size: 11pt;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        width: 100%;
    }

    .section-info-consulting .info-01 h1 {
        color: #E35568;
        font-size: 26pt;
        margin-bottom: 1em;
        font-family: 'Inter', sans-serif;
        font-weight: 900;
    }

    .section-info-consulting .info-01 .imgIcon1 {
        width: 11em;
    }

    .section-info-value-option-consulting h1 {
        font-size: 17pt;
        color: #5B5353;
        font-weight: 900;
        font-family: 'Inter', sans-serif;
        margin-bottom: 1em;
        margin-top: 1em;
    }

    .section-info-value-option-consulting h1 span>span {
        font-size: 9pt;
    }


    .section-info-value-option-consulting .content-info-services {
        display: none;
    }

    .content-info-services-mobile .option-service-mob {
        display: flex;
        background-color: #fff;
        padding: 1em;
        border-radius: 0.5em;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        color: #5B5353;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.192);
    }



    .option-service-mob img {
        margin-top: 0;
        width: 6em;
        height: 6em;
    }

    .option-service-mob p {
        margin-top: 0.5em;
        font-size: 10pt;
        font-family: 'Inter', sans-serif;
    }

    .org-text-info-ser p {
        margin-left: 1em;
        text-align: start;

    }

    .name-info-service-mob {
        margin-left: 1em;
        font-size: 11pt;
        text-align: start;
    }

    .section-info-tax-services h4 {
        font-size: 10pt;
        margin-bottom: 2em;
    }

    .section-info-tax-services h1 {
        font-size: 18pt;
        margin-bottom: 2em;
    }

    .content-info-service-value {
        display: none;
    }

    .content-info-service-value-mob {
        display: block;
    }

    .content-info-service-value-mob .option-service-mob {
        background-color: #fff;
        display: grid;
        grid-template-columns: 0fr 1fr;
        gap: 3em;
        width: 70%;
        margin: 0 auto;
        align-items: center;
        padding: 2em;
        border-radius: 1em;
        margin-bottom: 0.5em;
        color: #5B5353;
    }

    .content-info-service-value-mob .option-service-mob h1 {
        margin-bottom: 0em;
    }

    .content-info-service-value-mob .option-service-mob div {
        text-align: start;
    }

    .content-info-service-value-mob .option-service-mob div h1 {
        color: #E35568;

        font-size: 20pt;
    }

    .content-info-service-value-mob .option-service-mob div span {
        font-size: 12pt;
    }

    .info-bt-sv {
        width: 84%;
        margin: 0 auto;
        margin-top: 3em;
    }

    .info-bt-sv p {
        font-family: 'Inter', sans-serif;
        font-size: 12pt;
        font-weight: bold;
    }

    .section-bookkeeping-recursos .org-info-bookkeeping-recursos {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .section-bookkeeping-recursos h1 {
        text-align: center;
        color: #E35568;
        font-family: 'Inter', sans-serif;
        font-size: 25pt;
        font-weight: 900;
        margin-top: 2em;
        margin-bottom: 1em;
    }

    .div-bookeeping {
        display: flex;
        justify-content: center;
        margin-top: 1em;
    }

    .section-bookkeeping-recursos .org-info-bookkeeping-recursos p {
        font-family: 'Inter', sans-serif;
        font-size: 11pt;
        font-weight: bold;
        color: #5B5353;
    }

    .buttom-click-contato {
        background-color: #718F82;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        margin-top: 4em;
        padding: 1em 2em;
        padding-top: 1em;
        padding-right: 2em;
        padding-bottom: 1em;
        padding-left: 2em;
        border-radius: 0.5em;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 12pt;
        cursor: pointer;
        transition: all 0.5s;
    }

    .section-recuros .h1-rec {
        text-align: center;
        color: #E35568;
        font-family: 'Inter', sans-serif;
        font-size: 30pt;
        font-weight: 900;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .info-org-recursos {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .info-org-recursos {
        display: none;
    }

    .info-org-recursos-mob {
        display: block;
    }


    .info-org-recursos-mob .org-recursos {
        background-color: #fff;
        border-radius: 0.5em;
        padding: 1em;
        margin-bottom: 1em;
        display: grid;
        grid-template-columns: 0fr 1fr;
        gap: 2em;
        color: #5B5353;
    }

    .info-org-recursos-mob img {
        width: 5em;
        height: 5em;
        align-items: center;
        border-radius: 1em;
    }

    .section-bookkeeping-recursos .buttom-click-contato {
        background-color: #718F82;
        width: 50%;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        margin-top: 4em;
        padding: 1em 2em;
        border-radius: 0.5em;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 13pt;
        cursor: pointer;
        transition: all 0.5s;
    }

    .section-depoimentos .org-infos-depoismentos {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        width: 80%;
        margin: 0 auto;
    }

    .section-depoimentos h1 {
        color: #E35568;
        font-family: 'Inter', sans-serif;
        font-size: 30pt;
        font-weight: 900;
        text-align: center;
        padding-top: 1em;
        margin-bottom: 1em;
    }

    .section-depoimentos h1 {
        color: #E35568;
        font-family: 'Inter', sans-serif;
        font-size: 30pt;
        font-weight: 900;
        text-align: center;
        padding-top: 1em;
        margin-bottom: 1em;
    }

    .section-master .section-nosso-time {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        align-items: center;
        padding-top: 5em;
        padding-bottom: 2em;
    }

    .section-master .section-nosso-time h1 {
        color: #E35568;
        font-size: 23pt;
        font-weight: 900;
        font-family: 'Inter', sans-serif;
        margin-bottom: 1em;
        text-align: center;
    }

    .section-master .section-nosso-time p {
        color: #5B5353;
        font-size: 11pt;
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        text-align: center;
    }

    .section-master .section-nosso-time .aling_img img {
        display: none;
    }

    .section-master .section-nosso-timer-02 .i-img {
        width: 8em;
        height: 8em;
        background: #5B5353;
        border-radius: 25em;
        border: 5px solid #E35568;
    }

    .section-master .section-nosso-timer-02 .i-img img {
        width: 100%;
        width: 8em;
        height: 8em;
        border-radius: 25em;
    }

    .section-master .section-nosso-timer-02 .info-time-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        width: 50%;
        margin: 0 auto;
        justify-content: center;
    }

    .section-master .section-nosso-timer-02 .info-time-group .org-info-time h3 {
        margin-top: 0em;
        font-size: 14pt;
    }

    .org-info-time p {
        font-size: 10pt;
    }

    .icon-link {
        font-size: 23pt;
        color: #c73d50;
        cursor: pointer;
        margin-top: 0.5em;
        transition: color 0.2s ease;
    }

    .section-consultoria h1 {
        color: #536d62;
        font-weight: 900;
        font-family: 'Inter', sans-serif;
        padding-top: 2em;
        padding-bottom: 2em;
        font-size: 13pt;
    }

    .section-consultoria .org-info-consultoria {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        width: 90%;
        margin: 0 auto;
    }

    .section-consultoria .org-info-consultoria div {
        background-color: #fff;
        border: 4px solid #E35568;
        padding: 0em;
        border-radius: 1em;
    }

    .section-consultoria .button-agendar {
        background-color: #718F82;
        width: 65%;
        margin: 0 auto;
        border-radius: 0.5em;
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 9pt;
        font-weight: 900;
        padding: 1em 2em;
        margin-top: 3em;
        cursor: pointer;
        transition: all 0.5s;
        text-transform: uppercase;
    }

    .section-master .section-quemsomos h1,
    .section-redes-sociais h1,
    .section-certificados h1 {
        text-align: center;
        color: #E35568;
        font-size: 23pt;
        font-family: 'Inter', sans-serif;
        padding-top: 1em;
        padding-bottom: 1em;
        font-weight: 900;
    }

    .section-master .section-quemsomos p {
        color: #5B5353;
        border-left: none;
        padding-left: 0em;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 11pt;
    }

    .section-consultoria .org-info-consultoria div p {
        color: #E35568;
        font-weight: 900;
        font-family: 'Inter', sans-serif;
        padding-top: 2em;
        padding-bottom: 2em;
        font-size: 11pt;
    }

    .seciton-contato {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        gap: 1em;
        padding-bottom: 5em;
    }

    .seciton-contato img {
        width: 5em;
    }

    .section-master {
        width: 85%;
        margin: 0 auto;
    }

    .info-org-recursos-mob .org-recursos {
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.201);
    }

    .section-contato-form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        font-family: 'Inter', sans-serif;
        color: #5B5353;
    }

    textarea {
        max-width: 21em;
        min-width: 21em;
        max-height: 7em;
        min-height: 7em;
    }

    .section-contato-form input,
    textarea {
        padding: 1em 2em;
        border-radius: 0.5em;
        border: none;
        width: 8em;
        background: #e3e3e3;
        font-family: 'Inter', sans-serif;
    }

    .input-email {
        width: 21em !important;
    }

    .section-contato-form .button-contato {
        background-color: #718F82;
        color: #fff;
        width: auto;
        text-align: center;
        padding: 1em 2em;
        border-radius: 0.5em;
        cursor: pointer;
        margin-top: 2em;
    }

    .hr-footer {
        border: 2px solid #E35568 !important;
    }

    .section-master .section-quemsomos {
        padding-bottom: 1em;
        padding-top: 3em;
    }

    .content-footer .org-info-footer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        align-items: center;
        text-align: left;
    }

    .content-footer .org-info-footer .menu-footer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0em;
        align-items: center;
        font-size: 14pt;
        text-align: left;
        width: 80%;
        margin: 0 auto;
        font-weight: 600;
    }

    .org-img-icons {
        display: grid;
        width: 100%;
    }

    .icones a .icone-tam {
        font-size: 2.5em;
    }

    .mobile-menu-toggle {
        display: flex !important;
        justify-content: end;
    }

    .section-depoimentos .info-depoimentos h2 {
        color: #536d62;
        margin-bottom: 1em;
        font-size: 14pt;
    }

    .section-depoimentos .info-depoimentos p {
        color: #536d62;
        margin-bottom: 1em;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 10pt;
    }

    .section-depoimentos .info-depoimentos {
        background-color: #ffffff;
        padding: 1em;
        border-radius: 2em;
        border-bottom-right-radius: 0em;
        border: 3px solid #536d62;
        color: #5B5353;
    }

    .section-master .section-nosso-timer-02 .i-img {
        width: 8em;
        height: 8em;
        background: #ffffff;
        border-radius: 25em;
        border: 5px solid #E35568;
    }

    .seciton-contato p {
        display: block;
    }

    .content-info-services-mobile .option-service-mob {
        transition: all 0.5s;
    }

    .content-info-services-mobile .option-service-mob:hover {
        background-color: #E35568;
        color: #fff;
    }

    .content-info-services-mobile .option-service-mob:hover img {
        filter: brightness(0) invert(1);
        /* Define o brilho da imagem para 0, tornando-a completamente branca */
    }


    .container-modal-page {
        background-color: #c73d50;
        width: 80%;
        margin: 0 auto;
        padding: 2em;
        border-radius: 1em;
        color: #ffffff;
        top: 5em;
        position: relative;
    }

    .two-input {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        width: 100%;
    }

    .one-input {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        width: 100%;

    }

    .two-input input,
    .one-input input {
        background-color: #fff;
        border-radius: 0.5em;
    }

    .container-modal-page h2 {
        color: #fff;
    }

    .button-modal {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        width: 100%;
    }

    .button-modal .btn {
        margin-top: 1em;
        background: #fff;
        font-size: 15pt;
        color: #c73d50;
        border-radius: 0.5em;
        font-weight: bolder;
    }

    .button-modal .btn:hover {
        background: #fff;
        color: #c73d50;
    }

    .org-info-img-tax {
        display: grid;
        justify-content: center;
        align-items: center;
    }

    h2 {
        margin-top: 0em !important;
    }

    .section-info-tax-services h2 {
        padding-top: 2em;
        margin-top: 2em;
        font-size: 30pt;
        margin-bottom: 1em;
        margin-top: 1em !important;
    }

    .mobile-button-rs{
        display: block;
        width: 25%;
        margin: 0 auto;
    }
    .mobile-button-rs a {
        background-color: #718F82 !important;
        color: #fff !important;
        border-radius: 0.5em !important;
        padding: 0.5em 1em !important;
        font-weight: 900 !important;
        font-size: 10pt !important;
        display: flex !important;
        align-items: center !important;
        margin-top: 2em;
    }

    .content-iframe {
        width: calc(25% - 10px);
        height: 29vh;
        padding-bottom: 0em !important;
        position: relative;
    }
}