.section-agendamento-cliente {
    width: 65%;
    margin: 0 auto;
    padding: 3em;
    background-color: #fff;
    margin-top: 2em;
    margin-bottom: 1em;
}

.section-agendamento-cliente .input-one {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
}

.section-agendamento-cliente .input-two {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-top: 1em;
}

.button-option {
    margin-top: 1em;
    display: flex;
    justify-content: end;
}

.button-option .button {

    background-color: #E35568;
}

.button-option .button:hover {

    background-color: #c63d4f;
}

h2 {
    text-align: center;
    color: #5B5353;
    margin-top: 2em;
    margin-bottom: 1em;
}



element.style {
    height: 830px;
}

.input-one textarea {
    max-width: 100% !important;
    min-width: 100% !important;
    max-height: 166px;
    min-height: 166px;
}

@media (max-width: 768px) {
    h2 {
        text-align: center;
        color: #5B5353;
        margin-top: 2em;
        margin-bottom: 1em;
        font-size: 13pt;
    }

    .section-agendamento-cliente .input-two {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        margin-top: 1em;
    }

    .button-option .button {
        background-color: #E35568;
        width: 100%;
    }
}