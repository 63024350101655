@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Montserrat:wght@500;600;700;800;900&family=Roboto:wght@300&display=swap');

.section-info-slider {
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.section-info-slider::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.652);
    /* Cor cinza escuro com 15% de opacidade */
    z-index: -1;
}

.seciton-contato p {
    display: none;
}

.section-info-slider::after {
    content: "";
    background-image: url('../../assets/imgSlide/image.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}

hr {
    border: 3px solid #E35568 !important;
}

.content-info-home {
    width: 80%;
    margin: 0 auto;
}

.content-info-home h1 {
    color: #fff;
    font-weight: 900;
    font-size: 48pt;
    font-family: 'Inter', sans-serif;
    line-height: 1em;
}

.content-info-home .buttom-flc {
    margin-top: 2em;
    padding: 1em 6em;
    font-size: 20pt;
    font-weight: bold;
    border: none;
    border-radius: 0.5em;
    color: #fff;
    background-color: #E35568;
    cursor: pointer;
    transition: all 0.5s;
    text-transform: uppercase;
}

.content-info-home .buttom-flc:hover {

    background-color: #c73d50;

}

.section-master {
    width: 75%;
    margin: 0 auto;
}


.section-info-consulting {
    height: 50vh;
    margin-top: 6em;

}

.section-info-consulting .info-01 {
    display: grid;
    grid-template-columns: 1fr 0fr;
    gap: 1em;
    align-items: center;

}

.section-info-consulting .info-01 h1 {
    color: #E35568;
    font-size: 30pt;
    margin-bottom: 1em;
    font-family: 'Inter', sans-serif;
    font-weight: 900;
}

.section-info-consulting .info-01 p {
    color: #5B5353;
    font-size: 18pt;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    width: 85%;

}

.section-info-consulting .info-01 .imgIcon1 {
    width: 15em;
}

.section-info-value-option-consulting {
    text-align: center;

    margin-top: 0em;
}

.section-info-value-option-consulting h1 {
    font-size: 33pt;
    color: #5B5353;
    font-weight: 900;
    font-family: 'Inter', sans-serif;
}

.section-info-value-option-consulting h1 span {
    color: #E35568;
}

.section-info-value-option-consulting h1 span>span {
    font-size: 16pt;
}

.section-info-value-option-consulting .content-info-services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
    margin-top: 4em;
}

.section-info-value-option-consulting .content-info-services .option-service img {
    width: 10em;
    padding-top: 4em;
    padding-bottom: 3em;
}

.section-info-value-option-consulting .content-info-services .option-service {
    background: #fff;
    border-radius: 0.5em;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.148);
    transition: all 0.8s;
}


.section-info-value-option-consulting .content-info-services .option-service:hover {
    background: #E35568;
    color: #fff !important;
}

.section-info-value-option-consulting .content-info-services .option-service:hover h2 {
    color: #fff;
}

.section-info-value-option-consulting .content-info-services .option-service:hover div p {
    color: #fff;
}

.section-info-value-option-consulting .content-info-services .option-service:hover img {
    filter: brightness(0) invert(1);
}

.section-info-value-option-consulting .content-info-services .option-service h2 {
    color: #767070;
    font-weight: 800;
    font-size: 22pt;
    font-family: 'Inter', sans-serif;
}

.section-info-value-option-consulting .content-info-services .option-service div p {

    width: 85%;
    margin: 0 auto;
    margin-top: 2em;
    color: #767070;
    font-weight: 500;
    font-size: 14pt;
    font-family: 'Inter', sans-serif;
    margin-bottom: 1em;
}

.section-info-tax-services {
    background-color: #E35568;
    text-align: center;
    color: #fff;
    padding-bottom: 4em;

}

.section-info-tax-services h1,
h4,
h2 {
    font-weight: 900;
    font-family: 'Inter', sans-serif;
}

.section-info-tax-services h2 {
    padding-top: 2em;
    margin-top: 2em;
    font-size: 30pt;
    margin-bottom: 1em;
}

.section-info-tax-services h4 {
    font-size: 16pt;
    margin-bottom: 2em;
}

.section-info-tax-services h1 {
    font-size: 26pt;
    margin-bottom: 2em;
}

.content-info-service-value {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    width: 80%;
    margin: 0 auto;
}

.option-service {
    background-color: #fff;
    border-radius: 0.5em;
    padding: 1em;
    color: #5B5353;
    cursor: pointer;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.148);

}

.option-service img {
    margin-top: 2em;
    width: 9em;
}

.option-service .img02 {
    width: 10em !important;
}

.option-service h3 {

    margin-top: 2em;
    font-size: 22pt;
    font-weight: 900;
    font-family: '';
    font-family: 'Inter', sans-serif;
}

.option-service h1 {

    margin-top: 0.2em;
    font-size: 38pt;
    font-weight: 900;
    font-family: '';
    font-family: 'Inter', sans-serif;
    color: #E35568;
    margin-bottom: 1em;
}

.option-service p {

    margin-top: 0.5em;
    font-size: 18pt;
    font-family: 'Inter', sans-serif;
}

.option-service h1 span {
    font-size: 28pt;
}


.info-bt-sv {
    width: 50%;
    margin: 0 auto;
    margin-top: 7em;
}

.info-bt-sv p {
    font-family: 'Inter', sans-serif;
    font-size: 20pt;
    font-weight: bold;
}

.info-bt-sv .buttom-click-list {
    background-color: #718F82;
    margin-top: 2em;
    padding: 1em 2em;
    border-radius: 0.5em;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.5s;
    color: #fff;
}

.section-info-tax-services h2 {
    color: #fff;
}

.info-bt-sv .buttom-click-list:hover {
    background-color: #536d62;

}

.section-bookkeeping-recursos {
    margin: 0 auto;
    margin-bottom: 5em;

}

.section-bookkeeping-recursos h1 {
    text-align: center;
    color: #E35568;
    font-family: 'Inter', sans-serif;
    font-size: 30pt;
    font-weight: 900;
    margin-top: 3em;
    margin-bottom: 2em;

}

.section-bookkeeping-recursos .org-info-bookkeeping-recursos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    align-items: center;
}

.section-bookkeeping-recursos .org-info-bookkeeping-recursos h1 {
    margin-top: 0em;
    margin-bottom: 0em;
}

.section-bookkeeping-recursos .org-info-bookkeeping-recursos p {
    font-family: 'Inter', sans-serif;
    font-size: 14pt;
    font-weight: bold;
    color: #5B5353;
}

.section-bookkeeping-recursos .buttom-click-contato {
    background-color: #718F82;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-top: 4em;
    padding: 1em 2em;
    border-radius: 0.5em;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 20pt;
    cursor: pointer;
    transition: all 0.5s;
}

.section-bookkeeping-recursos .buttom-click-contato:hover {
    background-color: #536d62;
}

.info-org-recursos {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}


.info-org-recursos .org-recursos {
    background: #fff;
    border-radius: 0.5em;
    color: #5B5353;
    cursor: pointer;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.148);
    margin-left: 1em;

}

.info-org-recursos .org-recursos img {
    height: 17em;
    width: 100%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.info-org-recursos .org-recursos h4 {
    padding: 1em 2em;
}

.info-org-recursos .org-recursos p {
    padding: 0em 2em 1em 2em;
}

.option-ver-mias {

    text-align: end;
    margin-top: 1.5em;
    color: #E35568;
    cursor: pointer;
    font-size: 14pt;
    font-weight: 900;
}


.section-depoimentos {
    background-color: #EADED0;
    padding-bottom: 5em;
}


.section-depoimentos h1 {
    color: #E35568;
    font-family: 'Inter', sans-serif;
    font-size: 30pt;
    font-weight: 900;
    text-align: center;
    padding-top: 2em;
    margin-bottom: 2em;
}


.section-depoimentos .info-depoimentos {
    background-color: #ffffff;
    padding: 1.5em;
    border-radius: 3em;
    border-bottom-right-radius: 0em;
    border: 3px solid #536d62;
    color: #5B5353;

}

.section-depoimentos .org-infos-depoismentos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;

    width: 80%;
    margin: 0 auto;
}


.section-depoimentos .info-depoimentos h2 {
    color: #536d62;
    margin-bottom: 1em;
}

.section-depoimentos .info-depoimentos p {
    color: #536d62;
    margin-bottom: 1em;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.section-master .section-nosso-time {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

    align-items: center;
    padding-top: 5em;
    padding-bottom: 5em;
}

.section-master .section-nosso-time h1 {

    color: #E35568;
    font-size: 30pt;
    font-weight: 900;
    font-family: 'Inter', sans-serif;
    margin-bottom: 1em;
}

.section-master .section-nosso-time p {

    color: #5B5353;
    font-size: 14pt;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}

.section-master .section-nosso-time .aling_img {

    display: flex;
    justify-content: end;
}

.section-master .section-nosso-time .aling_img img {
    width: 20em;
}

.section-master .section-nosso-timer-02 .info-time-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
    margin: 0 auto;
    justify-content: center;

}

.section-master .section-nosso-timer-02 .info-time-group .org-info-time {
    display: grid;
    justify-content: center;
    align-items: center;

}

.section-master .section-nosso-timer-02 {
    text-align: center;
    margin-bottom: 3em;
}

.section-master .section-nosso-timer-02 .i-img {
    width: 13em;
    height: 13em;
    background: #ffffff;
    border-radius: 25em;
    border: 5px solid #E35568;
}

.section-master .section-nosso-timer-02 .i-img img {
    width: 100%;
    width: 13em;
    height: 13em;
    border-radius: 25em;
}

.section-master .section-nosso-timer-02 .info-time-group .org-info-time {
    color: #5B5353;

}

.section-master .section-nosso-timer-02 .info-time-group .org-info-time h3 {
    margin-top: 0em;
    font-size: 18pt;

}

.section-consultoria {
    background-color: #EADED0;
    text-align: center;
    padding-bottom: 5em;
}

.section-consultoria h1 {
    color: #536d62;
    font-weight: 900;
    font-family: 'Inter', sans-serif;
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: 28pt;


}

.section-consultoria .org-info-consultoria {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    width: 75%;
    margin: 0 auto;

}

.section-consultoria .org-info-consultoria div {
    background-color: #fff;
    border: 4px solid #E35568;
    padding: 2em;
    border-radius: 1em;
}

.section-consultoria .org-info-consultoria div p {
    color: #E35568;
    font-weight: 900;
    font-family: 'Inter', sans-serif;
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: 14pt;
}

.section-consultoria .button-agendar {
    background-color: #718F82;
    width: 50%;
    margin: 0 auto;
    border-radius: 0.5em;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 14pt;
    font-weight: 900;
    padding: 1em 2em;
    margin-top: 3em;
    cursor: pointer;
    transition: all 0.5s;
    text-transform: uppercase;

}

.section-consultoria .button-agendar:hover {
    background-color: #536d62;
}

.section-master .section-quemsomos {
    padding-bottom: 9em;
    padding-top: 3em;


}

.section-master .section-quemsomos h1,
.section-redes-sociais h1,
.section-certificados h1 {
    text-align: center;
    color: #E35568;
    font-size: 30pt;
    font-family: 'Inter', sans-serif;
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: 900;
}


.section-master .section-quemsomos p {
    color: #5B5353;
    border-left: 5px solid #536d62;
    padding-left: 2em;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16pt;
}


.section-redes-sociais {
    background-color: #EADED0;
    text-align: center;
    padding-bottom: 5em;
}

.seciton-contato {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    padding-bottom: 5em;
}




.section-contato-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    font-family: 'Inter', sans-serif;
    color: #5B5353;
}

.section-contato-form h1 {

    color: #E35568;
    font-size: 25pt;
    font-family: 'Inter', sans-serif;
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: 900;
}

.section-contato-form .button-contato {

    background-color: #718F82;
    color: #fff;
    width: 55%;
    text-align: center;
    padding: 1em 2em;
    border-radius: 0.5em;
    cursor: pointer;
    margin-top: 2em;

}

.section-contato-form input,
textarea {

    padding: 1em 2em;
    border-radius: 0.5em;
    border: none;
    background: #e3e3e3;
    font-family: 'Inter', sans-serif;

}

.section-contato-form .input-2 {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    width: 100%;
    margin-bottom: 1em;
    margin-top: 2em;

}

.section-contato-form .input-1 {

    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 1em;
}

.section-contato-form form button {

    font-family: 'Inter', sans-serif;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 1em 2em;
    border: none;
    background-color: #E35568;
    color: #fff;
    font-size: 12pt;
    border-radius: 0.5em;
    cursor: pointer;
}

.input-2 div {
    background: #e3e3e3 !important;
}

.input-1 div {
    background: #e3e3e3 !important;
}




.content-info-org {
    width: 85%;
    margin: 0 auto;
}


.org-img-icons {
    display: grid;
    width: 75%;

}

.org-img-icons div {
    display: flex;
    justify-content: center;
}

.icones {
    margin-top: 0.5em;
}

.icones a {
    margin-left: 0.5em;
    text-decoration: none !important;
    color: #fff;
    transition: all 0.5s;
}

.icones a:hover {

    color: #E35568;
}

.section-recuros .h1-rec {
    text-align: center;
    color: #E35568;
    font-family: 'Inter', sans-serif;
    font-size: 30pt;
    font-weight: 900;
    margin-top: 3em;
    margin-bottom: 2em;
}

.section-recuros {
    margin-bottom: 5em;
}

.div-bookeeping {
    display: flex;
    justify-content: end;
}

.container-modal-page {
    background-color: #c73d50;
    width: 40%;
    margin: 0 auto;
    padding: 2em;
    border-radius: 1em;
    color: #ffffff;
    top: 5em;
    position: relative;
}

.two-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    width: 100%;
}

.one-input {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    width: 100%;

}

.two-input input,
.one-input input {
    background-color: #fff;
    border-radius: 0.5em;
}

.container-modal-page h2 {
    color: #fff;
}

.button-modal {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    width: 100%;
}

.button-modal .btn {
    margin-top: 1em;
    background: #fff;
    font-size: 15pt;
    color: #c73d50;
    border-radius: 0.5em;
    font-weight: bolder;
}
.button-modal .btn:hover {
    background: #fff;
    color: #c73d50;
}


.section-bookkeeping-recursos .org-info-bookkeeping-recursos p {
    font-family: 'Inter', sans-serif;
    font-size: 18pt;
    font-weight: bold;
    color: #5B5353;
}

.section-bookkeeping-recursos .org-info-bookkeeping-recursos {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1em;
    align-items: center;
}
.icon-link{
    font-size: 30pt;
    color: #c73d50;
    cursor: pointer;
    margin-top: 0.5em;
    transition: color 0.2s ease;
}
.icon-link:hover{
    font-size: 30pt;
    color: #E35568;
    cursor: pointer;
    margin-top: 0.5em;
}