@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&family=Roboto:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
}

body{
    background-color: #F5F5F5;
    font-family: "Roboto", sans-serif;
}

.hr-footer {
    border: 3px solid #5B5353 !important;
}
.content-footer {
    background-color: #0A0A0A;
    color: #fff;
    font-family: 'Inter', sans-serif;
    text-align: center;
    padding-top: 2em;
    margin-top: 1em;
    padding-bottom: 2em;

}

.content-footer .org-info-footer {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: 1em;
    align-items: center;
    text-align: left;


}

.content-footer .org-info-footer .menu-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

    align-items: center;
    font-size: 14pt;
    text-align: left;

    

}
.content-footer .org-info-footer .menu-footer p {
   
    color: #fff;
    cursor: pointer;
    transition: all 0.5s;
    margin-bottom: 0.5em;

}
.content-footer .org-info-footer .menu-footer p:hover {
   
    color: #E35568;


}

.content-footer img {
    width: 11em;
   
}

.footer-hr {
    border: 1px solid #ffffff !important;
    margin-bottom: 1em;
    margin-top: 1em;

}


a {
    text-decoration: none;
    color: #5B5353;
}


@media (max-width: 768px) {
    .container-info-name a{
        display: none !important;
    }
    
    .container-info-name {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0em;
        margin-bottom: 1em;
        color: #718F82 !important;
    }
    

}