.content-post h2 {

    text-align: initial !important;
    font-size: 28pt;
}

.content-post .cria {

    margin-bottom: 1em;
}

.container-img-bl {

    display: flex;
    justify-content: center;
    margin: 5em;
}

.container-img-bl img {
    width: 50em;
}

.content-post {

    color: #5B5353;

}

.text-complete {
    font-size: 16pt;
}

.comentarios-blog {
    background-color: #fff;
    padding: 1em;
    margin-top: 2em;
    border-radius: 0.5em;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.087);
    color: #5B5353;
}

.conteiner-info {
    display: grid;
    grid-template-columns: 0fr 1fr;
    gap: 1em;
    margin-bottom: 1em;
}

.icon {
    background-color: #5B5353;
    width: 2em;
    height: 2em;
    border-radius: 5em;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #ececec;
}

.input-one {
    width: 100%;
}
.input-one div{
    width: 100%;
}


.cria-coment {
    font-size: 7pt;
    text-align: end;
    margin-bottom: 1em;
}

.coment-hr {
    border: 1px solid #777777 !important;
    margin-bottom: 1em;
}

.vermais-bt {
    padding: 1em 2em;
    border: none;
    color: #fff;
    background: #777777;
    border-radius: 0.5em;
    cursor: pointer;
    font-weight: 800;
}

@media (max-width: 768px) {
    .container-img-bl img {
        width: 20em;
    }

    .container-section-blog h1 {
        color: #5B5353;
        font-weight: 900;
        font-size: 24pt;
        margin-bottom: 0em;
        margin-top: 2em;

    }

    .container-section-blog {
        width: 90%;
        margin: 0 auto;
    }

    .text-complete p {
        text-align: justify;
    }
}