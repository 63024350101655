.video-youtube-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1em;
    width: 95%;
    margin: 0 auto;
}

.content-iframe {
    border-radius: 0.5em;
    width: 300px;
    height: 170px
}

.container-info-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2em;
    color: #718F82 !important;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 1em;

}

.container-info-name h2 {
    text-align: center;
    margin-top: 0em;
    color: #718F82 !important;
    margin-bottom: 0em;
    margin-left: 0.5em;

}

.org-name-icon {
    display: flex;
    align-items: center;
}

.container-info-name a {
    background-color: #718F82;
    color: #fff;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    font-weight: 900;
    font-size: 10pt;
    display: flex;
    align-items: center;
}


/* styles.css */

.video-youtube-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;  /* Espaço entre as miniaturas */
}

.content-iframe {
    width: calc(25% - 10px); /* Define a largura de cada miniatura para 25% da largura do contêiner e subtrai o espaço entre as miniaturas */
    height: 29vh ;
    padding-bottom: 3em;  /* Proporção 1:1 para as miniaturas */
    position: relative;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


@media (max-width: 768px) {
    

    .content-iframe {
        border-radius: 0.5em;
        width: 150px;
        height: 85px
    }

    .video-youtube-container {
        display: flex;
        justify-content: center;
    }

    .container-info-name h2 {
        margin-left: 0em;
    }

}