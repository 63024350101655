.container-section-blog {
    width: 75%;
    margin: 0 auto;
}

.container-section-blog h1 {
    color: #5B5353;
    font-weight: 900;
    font-size: 24pt;
    margin-bottom: 1em;
    margin-top: 3em;
}

.button-down h3 {
    background-color: #E35568 !important;
    padding: 0.2em 0.4em;
    color: #fff;
    border-radius: 0.5em;
    cursor: pointer;
    transition: all 0.5s;
}

.blog-info p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}