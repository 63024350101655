.container-info-name {
    margin-top: 1em;
}


.content-image,
.content-video {
    border-radius: 0.5em;
    width: 300px;
    height: 170px
}

.post-instagram-container {
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr ;
    gap: 1em;
    place-items: center;
  }
  
  .content-image, .content-video {
    width: 20em;
    height: 20em;
    object-fit: cover;
    border-radius: 5px;
    transition: opacity 0.3s ease;
  }
  
  .content-image:hover, .content-video:hover {
    opacity: 0.8;
  }
  

  
@media (max-width: 768px) {
    .container-info-name {

        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0em;
        margin-bottom: 1em;
        color: #718F82 !important;
    }

    .content-image,
    .content-video {
        border-radius: 0.5em;
        width: 150px;
        height: 85px
    }

    .content-image,
    .content-video {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0em;
    }

    .container-info-name h2 {
        margin-left: 0em;
    }

    .post-instagram-container {
        display: flex;
        justify-content: center;
    }

    .post-instagram-container {
        grid-template-columns: repeat(2, 1fr); /* Mostra duas postagens por linha */
        gap: 10px;
      }
    
      .content-image, .content-video {
        max-width: none; /* Permite que as imagens cresçam para preencher o espaço disponível */
      }
    


}